export default function IconLinkedin() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4554 0H1.94983C0.87175 0 0 0.851117 0 1.90341V24.5019C0 25.5541 0.87175 26.4104 1.94983 26.4104H24.4554C25.5335 26.4104 26.4104 25.5541 26.4104 24.507V1.90341C26.4104 0.851117 25.5335 0 24.4554 0ZM7.83544 22.5056H3.91514V9.89875H7.83544V22.5056ZM5.87529 8.18104C4.61667 8.18104 3.60048 7.16486 3.60048 5.9114C3.60048 4.65793 4.61667 3.64175 5.87529 3.64175C7.12875 3.64175 8.14493 4.65793 8.14493 5.9114C8.14493 7.1597 7.12875 8.18104 5.87529 8.18104ZM22.5056 22.5056H18.5905V16.3776C18.5905 14.9178 18.5647 13.035 16.5529 13.035C14.5154 13.035 14.2059 14.6289 14.2059 16.2744V22.5056H10.2959V9.89875H14.0512V11.6216H14.1028C14.6237 10.6312 15.903 9.5841 17.8064 9.5841C21.7731 9.5841 22.5056 12.1942 22.5056 15.5883V22.5056Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default function IconTwitter() {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.30556 21.4591C18.2722 21.4591 23.7221 13.2017 23.7221 6.04263C23.7221 5.80832 23.7221 5.57397 23.7074 5.34175C24.7678 4.57445 25.6831 3.62448 26.4104 2.53629C25.4219 2.97472 24.3733 3.26257 23.2995 3.38998C24.4302 2.71217 25.2762 1.64669 25.6803 0.391892C24.6165 1.02338 23.4526 1.46852 22.2388 1.7079C20.5594 -0.0778018 17.8909 -0.514863 15.7297 0.641907C13.5684 1.79868 12.452 4.26156 13.0065 6.64938C8.64964 6.4311 4.59037 4.37328 1.83893 0.988157C0.399309 3.4633 1.13219 6.63127 3.51259 8.22265C2.65623 8.19372 1.8192 7.9606 1.07115 7.54273C1.07115 7.56574 1.07115 7.5887 1.07115 7.61172C1.07084 10.1879 2.88381 12.4084 5.40802 12.9235C4.60988 13.1409 3.77251 13.1724 2.96029 13.0155C3.66925 15.2241 5.70396 16.7376 8.02311 16.7814C6.10257 18.2854 3.73229 19.1003 1.29292 19.0952C0.860844 19.0954 0.429121 19.0703 0 19.0199C2.47759 20.6113 5.3609 21.4559 8.30556 21.4529"
        fill="currentColor"
      />
    </svg>
  );
}

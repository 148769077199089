export default function IconYoutube() {
  return (
    <svg
      width="31"
      height="22"
      viewBox="0 0 31 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5052 4.67598C30.5052 4.67598 30.2043 2.55163 29.2776 1.61884C28.1041 0.39117 26.7921 0.385152 26.1903 0.312936C21.8814 -1.72176e-07 15.4121 0 15.4121 0H15.4001C15.4001 0 8.93071 -1.72176e-07 4.62182 0.312936C4.02002 0.385152 2.7081 0.39117 1.53459 1.61884C0.607818 2.55163 0.312936 4.67598 0.312936 4.67598C0.312936 4.67598 0 7.17345 0 9.66491V11.9999C0 14.4913 0.306918 16.9888 0.306918 16.9888C0.306918 16.9888 0.607818 19.1132 1.52857 20.046C2.70208 21.2736 4.24269 21.2315 4.92874 21.3639C7.39612 21.5986 15.4061 21.6708 15.4061 21.6708C15.4061 21.6708 21.8814 21.6588 26.1903 21.3519C26.7921 21.2796 28.1041 21.2736 29.2776 20.046C30.2043 19.1132 30.5052 16.9888 30.5052 16.9888C30.5052 16.9888 30.8122 14.4974 30.8122 11.9999V9.66491C30.8122 7.17345 30.5052 4.67598 30.5052 4.67598ZM12.2226 14.8344V6.17447L20.5455 10.5195L12.2226 14.8344Z"
        fill="currentColor"
      />
    </svg>
  );
}
